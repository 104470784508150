import React from "react"
import Layout from "../components/containers/layout"
import Seo from "../components/containers/seo"
import { getRoute, ROUTES_ID } from "../constants/routes.contants"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/news.css"

const News = () => {
  const route = getRoute(ROUTES_ID.APROPOS)

  return (
    <>
      <Seo title={route.screenTitle} />
      <Layout className="news-page">
        <div className="column news-news-column">
          <div className="news-news-container">
            <h2>Le retour de l'inflation : parlons-en !</h2>
            <p className="text-ligth justify">
              Chers investisseurs,
              <br />
              Difficile d'y échapper en ce moment tant elle est partout. Non, il
              ne s’agit pas de la canicule mais du retour de
              <b> l'inflation</b>. Je souhaite passer en revue quelques points
              essentiels.
            </p>
            <h3>L'inflation, c’est quoi ?</h3>
            <p className="text-ligth justify">
              L'inflation est définie par l'INSEE comme une augmentation
              <b> générale</b> et <b>durable</b> des <b>prix</b> :
              <ul>
                <li>
                  générale : elle touche plusieurs secteurs de biens et
                  services,
                </li>
                <li>
                  durable : elle s'installe dans le temps et s'accompagne
                  généralement d'une augmentation des salaires,
                </li>
                <li>
                  prix : panier de biens et services représentatifs de la
                  consommation des ménages.
                </li>
              </ul>
            </p>
            <h3>Quel impact sur mon épargne ?</h3>
            <p className="text-ligth justify">
              L'inflation dévalue progressivement la valeur de la monnaie. 1 €
              dépensé aujourd'hui m'offre plus qu'1 € dépensé dans le futur.
              <br />
              Avec une faible inflation, les livrets et fonds en euros protègent
              en partie votre épargne. Ce n'est plus le cas avec une inflation
              nettement plus importante que le taux de rémunération de votre
              épargne. <br />
              L'inflation <b>grignote</b> progressivement le pouvoir d'achat de
              votre épargne.
            </p>
            <h3>Que faut-il faire ?</h3>
            <p className="text-ligth justify">
              Pour préparer vos projets à long terme tout en conservant votre
              pouvoir d'achat, il est nécessaire de placer votre épargne à un
              taux supérieur à l'inflation. Cela passe nécessairement par de{" "}
              <b>l'investissement.</b>
            </p>
            <br />
            <p className="text-ligth justify">
              <b>On te voit venir...</b>
            </p>
            <br />
            <p className="text-ligth justify">
              Alors, oui et non.
              <br />
              L'objectif de ma communication n'est pas de vous convaincre
              d'investir, puisque vous faites déjà partie de mes investisseurs.
            </p>
            <p className="text-ligth justify">
              Je ne suis évidemment pas le seul à faire ce constat sur la perte
              de pouvoir d'achat de l'épargne. Et la part du gâteau est immense
              : les français accumulent 1 700 Milliards d'€ sur leurs livrets et
              comptes courants ! Sans compter les fonds en euros.
              <br />
              Cela attire les convoitises souvent accompagnées de mauvaises
              intentions... En jouant sur les émotions et sur un sentiment
              d'urgence, le risque est grand d'investir à tort et à travers. Et
              ni la peur ni la précipitation ne sont les amies de l’investisseur
              !
            </p>
            <div class="img-container">
              <div class="img-wrapper">
                <StaticImage
                  src="../images/news-fantasy.jpg"
                  alt="fantasy shadow"
                />
              </div>
            </div>
            <h3>Parlez en autour de vous !</h3>
            <p className="text-ligth justify">
              Vous qui avez été accompagnés pour réaliser vos investissements,
              conformément à vos <b>projets de vie</b>, vous savez qu’un bon
              investissement n’est pas une offre en tête de gondole mais un
              produit qui <b>vous</b> correspond. Et cela nécessite un peu de
              travail, celui que nous avons réalisé ensemble.
              <br />
              Grâce à cela, vous êtes sur la bonne voie pour traverser ce
              nouveau changement d’ère économique… Comme vous l’avez déjà fait
              avec la crise Covid !
            </p>
            <p className="text-ligth justify">
              Alors ne gardez pas ces bonnes pratiques pour vous, parlez-en à
              votre entourage pour que je puisse aussi{" "}
              <b>les aider et les conseiller</b>.
            </p>
            <div class="img-container">
              <div class="img-wrapper">
                <StaticImage
                  src="../images/news-giraffes.jpg"
                  alt="spoking giraffes"
                />
              </div>
            </div>
            <p className="text-ligth justify">
              La recommandation est mon seul levier de développement, parce
              qu'il est un gage de <b>qualité</b> dans la relation
              conseiller-investisseur. Vous ne trouverez pas mes conseils
              sponsorisés sur les réseaux sociaux !
            </p>
          </div>
          <div className="news-news-container">
            <h2>Déclaration d'impôt 2021 sur les revenus 2020</h2>
            <p className="text-ligth justify">
              C'est ouvert, enfin ! C'est le moment pour moi d'
              <i>accompagner</i> mes investisseurs pour les investissements
              réalisés ensemble.
              <br />
              Mais c'est aussi l'occasion de{" "}
              <b>
                <i>vous aider dans votre déclaration</i>
              </b>
              , de{" "}
              <b>
                <i>comprendre votre fiscalité</i>
              </b>
              , ou de chercher à l'<b>optimiser</b> ! Bref, c'est le bon moment
              pour passer du temps ensemble.
              <br />
              Que ce soit{" "}
              <i>
                vos revenus salariés, ceux de vos activités personnelles, vos
                revenus fonciers, mais également vos réductions d'impôt
              </i>{" "}
              (capital investissement, épargne retraite), il y en a à déclarer !
              Et quelques nouveautés avec la déclaration des{" "}
              <i>comptes étrangers</i>, notamment si vous avez un petit
              portefeuille de crypto !<br />A vos claviers et bon courage.
            </p>
          </div>
          <div className="news-news-container">
            <h2>Palmarès 2021 des investissements</h2>
            <p className="text-ligth justify">
              Gestion de Fortune a publié récemment le palmarès des meilleurs
              sociétés avec qui{" "}
              <b>
                <u>
                  <a
                    href="https://palmares.gestiondefortune.com/resultats-2021.html"
                    onClick={() =>
                      window.gtag("event", "navigation", {
                        event_category: "page-content",
                        event_label:
                          "https://palmares.gestiondefortune.com/resultats-2021.html",
                      })
                    }
                  >
                    (investir en 2021)
                  </a>
                </u>
              </b>
              <br />
              <br />
              Et oh surprise !
              <br />
              Je retrouve mes fournisseurs sur tous les podiums ! Que ce soit
              dans les <i>sociétés de gestion</i> (Moneta, Pictet, Comgest, la
              financière, ...), <i>l'assurance vie</i> (Vie Plus),{" "}
              <i>l'épargne retraite</i> (Eres), les <i>SCPI</i> (Voisin, Perial,
              Urban Premium), <i>l'immo</i> (Groupe Cir, PERL, Novaxia), le{" "}
              <i>capital invest</i> (Inter Invest, Eiffel) et évidemment{" "}
              <b>Inter Invest</b> encore sur le <i>Girardin</i>.
              <br />
              Comme je le dis souvent,{" "}
              <u>un bon cuisinier a besoin de bons produits</u>,{" "}
              <b>un bon conseiller en investissement aussi</b>.
              <br />
              Et si vous voulez que je vous les présente,{" "}
              <a
                className="main-color"
                href="tel:0617338495"
                onClick={() =>
                  window.gtag("event", "click", {
                    event_category: "page-content",
                    event_label: "call",
                  })
                }
              >
                appelez moi !
              </a>
            </p>
          </div>
          <div className="news-news-container">
            <h2>Girardin</h2>
            <p className="text-ligth justify">
              Inter Invest ouvre les <b>réservations de Girardin</b> le{" "}
              <i>10 février</i> ! Cet investissement plébiscité qui s’appuie sur
              la fiscalité est saisonnier, les 2 ou 3 prochains mois seront donc{" "}
              <u>les seuls</u> pour pouvoir investir dans cette solution en 2021
              !
            </p>
          </div>
        </div>
        <div className="background-main-green-color column news-conferences-column">
          <h2>Conférences</h2>
          <p className="justify">
            T4 2020 aura été rythmé par 8 conférences les lundi soir. Si vous
            les avez manqués ou que voulez les revoir, les voilà en replay !
            <br />
            Et sinon, vous avez{" "}
            <u>
              <a
                className="main-color"
                href="/conseils/"
                onClick={() =>
                  window.gtag("event", "navigation", {
                    event_category: "page-content",
                    event_label: "conseils",
                  })
                }
              >
                la vision d'ensemble du monde de l'investissement
              </a>
            </u>
            .
          </p>
          <div className="row news-conferences-container">
            <iframe
              title="Pourquoi l'assurance vie est si mal connue"
              src="https://www.youtube.com/embed/5zRoNQfC2xw"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              title="Girardin Industriel, Noël avant l'heure"
              src="https://www.youtube.com/embed/baI5h3BIZr8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              title="La pierre papier ou SCPI, l'investissement à succès"
              src="https://www.youtube.com/embed/I-i3mMj7-Xs"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              title="les atouts de la nue-propriété"
              src="https://www.youtube.com/embed/Lcn73aJ_mAg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              title="Comment choisir un bien Pinel"
              src="https://www.youtube.com/embed/2M19okil2pk"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              title="Les paradoxes du crédit"
              src="https://www.youtube.com/embed/R2dbNDvEV7c"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              title="Boost du Capital Investissement sur fin 2020"
              src="https://www.youtube.com/embed/ia10QrZXtcg"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              title="Le nouveau Plan d’Epargne Retraite"
              src="https://www.youtube.com/embed/6FpbquNXJng"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default News
